import React, { useState, useEffect, useContext, useCallback } from "react";
import styled from "styled-components";

import { Context } from "../../AppContext";

const constants = require("../../constants");

const Item = props => {
  let {
    index,
    item,
    spec,
    columns,
    order,
    orderItems = [],
    updateOrderItems,
    promos,
  } = props;
  const [product, setProduct] = useState(null);

  const app = useContext(Context);

  useEffect(() => {
    (async () => {
      try {
        const product = await app.actions.getProductFromSpec({
          spec,
          productName:
            order?.order_type === "custom" ? "自定義商品" : item.name,
        });
        setProduct(product);
      } catch (err) {
        console.warn(err);
      }
    })();
  }, [app.actions, item.name, spec]);

  const orderItem = orderItems.find(
    orderItem => orderItem.item_index === index
  );
  let disabled = false;

  if (order && orderItem) {
    disabled =
      ["refunding", "refunded", "voided", "completed"].includes(
        order.display_state
      ) ||
      (orderItem && orderItem.status === "accepted");
  }

  if (!product || !item) {
    return null;
  }

  let params = {
    item,
    index,
    product,
    orderItem,
    disabled,
    updateOrderItems,
  };

  return (
    <Wrapper>
      <Row>
        {columns.map((col, idx) => (
          <Col
            key={idx}
            style={{
              ...col.style,
            }}
            className={col.className}
          >
            {typeof col.render === "function"
              ? col.render(params)
              : item[col.name]}
          </Col>
        ))}
      </Row>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  background-color: white;
  flex: ${props => props.flex || 1};
  padding: 20px 0;
  position: relative;
  border-bottom: solid 0.5px ${constants.colors.border};

  ${props => props.css}
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  flex: ${props => props.flex || 1};
  color: ${props => props.color || "#505050"};
  padding: ${props => props.padding || "0 20px"};

  &.spec {
    @media only screen and (max-width: ${constants.breakpoints.lg}px) {
      padding: 0 24px;
    }
  }

  &.quantity {
    flex: 0 0 130px;
    align-items: start;

    @media only screen and (max-width: ${constants.breakpoints.xl}px) {
      display: none;
    }
  }

  &.require-day {
    flex: 0 0 110px;
    align-items: start;

    @media only screen and (max-width: ${constants.breakpoints.lg}px) {
      display: none;
    }
  }

  &.price {
    flex: 0 0 130px;
    align-items: start;

    @media only screen and (max-width: ${constants.breakpoints.lg}px) {
      flex: 0 0 0px;
      padding: 0;

      span {
        display: none;
      }
    }
  }

  ${props => props.css}
`;

export default Item;
