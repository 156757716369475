const CUSTOMER_CONFIGS = {
  name: "",
  phone: "",
  email: "",
  tel: "",
  tel_ext: "",
  zip: "",
  city: null,
  district: null,
  address: "",
};

const SHIPPING_CONFIGS = {
  delivery_type: "hct",
  is_delivery_private: false,
  receiver_name: "",
  receiver_phone: "",
  receiver_phone_spare: "",
  receiver_tel: "",
  receiver_tel_ext: "",
  zip: "",
  receiver_city: "",
  receiver_district: "",
  receiver_address: "",
  delivery_note: "",
  // private delivery data:
  sender_name: "",
  sender_phone: "",
  sender_tel: "",
  sender_tel_ext: "",
  sender_zip: "",
  sender_city: null,
  sender_district: null,
  sender_address: "",
  // ezship data:
  st_state: "", // 通路代號
  st_code: "", // 門市代號
  rstore_name: "", // 門市名稱
  rstore_tel: "", // 門市電話
  rstore_addr: "", // 門市地址
};

const INVOICE_CONFIGS = {
  invoice_type: "two_copies",
  invoice_subtype: "",
  invoice_detail: "",
  citizen_personal_certificate_code: "", //自然人憑證條碼(16碼)
  mobile_vehicle_code: "", //手機載具(8碼)
  ezpay_vehicle_code: "",
  gui_number: "",
  company_title: "",
  donate_foundation_gui: "",
  love_code: "",
  email: "",
};

export { CUSTOMER_CONFIGS, SHIPPING_CONFIGS, INVOICE_CONFIGS };
