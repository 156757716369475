import React from "react";
import { connect } from "react-redux";
import CartItemTable from "./CartItemTable";

function NewCartDetail(props) {
  const { goToPrevStep, cartData } = props;

  return <CartItemTable goToPrevStep={goToPrevStep} cartData={cartData} />;
}

export default connect(
  (state, ownProps) => ({
    cartData: ownProps.cartData || null,
  }),
  null
)(NewCartDetail);
