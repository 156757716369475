import formatValidator from "./formatValidator";
import { ErrInvoiceFormat } from "../errors";
const {
  isPersonCertificatedCode,
  isMobileVehicleCode,
  isCompanyId,
  isLoveCode,
  isNotEmpty,
} = formatValidator;

export default function valid(config) {
  let {
    invoice_type,
    invoice_subtype = "",
    citizen_personal_certificate_code = "",
    mobile_vehicle_code = "",
    gui_number = "",
    company_title = "",
    donate_foundation_gui = "",
    love_code = "",
  } = config;

  // invoice config
  if (invoice_type === "two_copies") {
    if (invoice_subtype === "citizen_personal_certificate") {
      if (
        !isNotEmpty(citizen_personal_certificate_code) ||
        !isPersonCertificatedCode(citizen_personal_certificate_code)
      ) {
        throw new ErrInvoiceFormat("二聯式發票/自然人憑證格式錯誤");
      }
    } else if (invoice_subtype === "mobile_vehicle") {
      if (
        !isNotEmpty(mobile_vehicle_code) ||
        !isMobileVehicleCode(mobile_vehicle_code)
      ) {
        throw new ErrInvoiceFormat("二聯式發票/手機載具格式錯誤");
      }
    }
  }

  if (invoice_type === "three_copies") {
    if (
      !isNotEmpty(gui_number) ||
      !isCompanyId(gui_number) ||
      !isNotEmpty(company_title)
    ) {
      throw new ErrInvoiceFormat("三聯式發票格式錯誤");
    }
  }

  if (invoice_type === "donate") {
    // if (
    //   !isNotEmpty(donate_foundation_gui) ||
    //   !isCompanyId(donate_foundation_gui)
    // ) {
    //   return false;
    // }
    if (!isNotEmpty(love_code) || !isLoveCode(love_code)) {
      throw new ErrInvoiceFormat("捐贈發票格式錯誤");
    }
  }

  return true;
}
