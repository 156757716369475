import React, { useState, useContext } from "react";
import styled from "styled-components";
import { Modal, Checkbox } from "antd";
import { Button } from "../Widgets";
import { Context } from "../AppContext";

const constants = require("../constants");

export default function OrderReminder({ visible, onConfirm, onCancel }) {
  const app = useContext(Context);
  const { agreement } = app.state;
  const [isAgreed, setIsAgreed] = useState(false);

  const ensureExternalUrl = url => {
    if (!url) return "#";
    if (url.startsWith("http://") || url.startsWith("https://")) {
      return url;
    }
    return `https://${url}`;
  };

  const renderElement = (item, index) => {
    if (!item || typeof item !== "object") {
      console.warn(`Invalid item at index ${index}:`, item);
      return null;
    }

    if (typeof item.text === "string") {
      const style = {
        fontWeight: item.fontWeight,
        fontStyle: item.fontStyle,
        textDecoration: item.textDecoration,
        backgroundColor: item.backgroundColor,
        color: item.color,
      };
      return (
        <span key={index} style={style}>
          {item.text}
        </span>
      );
    }

    const Tag = item.type;

    const style = {
      textAlign: item.textAlign,
      maxWidth: item.maxWidth,
    };

    if (Tag === "img") {
      return <img key={index} src={item.src} style={style} alt="" />;
    }

    if (Tag === "a") {
      return (
        <a
          href={ensureExternalUrl(item.href)}
          style={style}
          target="_blank"
          rel="noopener noreferrer"
        >
          {renderChildren(item.children)}
        </a>
      );
    }

    return (
      <Tag key={index} style={style}>
        {renderChildren(item.children)}
      </Tag>
    );
  };

  const renderChildren = children => {
    if (!children) return null;
    return children.map((child, index) =>
      typeof child === "string" ? child : renderElement(child, index)
    );
  };

  if (!agreement || !Array.isArray(agreement.content)) {
    return null;
  }

  return (
    <StyledModal
      title="成立訂單前注意事項"
      visible={visible}
      onCancel={onCancel}
      footer={
        <Footer
          onConfirm={onConfirm}
          isAgreed={isAgreed}
          setIsAgreed={setIsAgreed}
        />
      }
      width="950px"
    >
      <div>
        {agreement?.content?.map((item, index) => renderElement(item, index))}
      </div>
    </StyledModal>
  );
}

const Footer = ({ onConfirm, isAgreed, setIsAgreed }) => {
  const handleChange = () => {
    setIsAgreed(!isAgreed);
  };

  return (
    <div>
      <Checkbox
        style={{ width: "100%", marginBottom: "22px" }}
        onClick={handleChange}
        checked={isAgreed}
      >
        我已閱讀並接受本網站
        <span style={{ color: constants.colors.highlight }}>
          條款聲明、印刷須知
        </span>
        所有注意事項及條款須知。
      </Checkbox>

      <Button
        size="lg"
        style={{ width: "100%" }}
        disabled={!isAgreed}
        onClick={onConfirm}
      >
        我已了解並同意注意事項！
      </Button>
    </div>
  );
};

const StyledModal = styled(Modal)`
  .ant-modal-close {
    display: none;
  }
  .ant-modal-content {
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  .ant-modal-header {
    height: 60px;
    border-radius: 16px 16px 0 0;
    padding: 24px;
    text-align: center;
    border: none;
    .ant-modal-title {
      line-height: 36px;
      font-size: 24px;
      font-weight: 700;
      color: #000;
    }
  }
  .ant-modal-body {
    padding: 0 24px;
    max-height: 60vh;
    overflow-y: auto;
  }
  .ant-modal-footer {
    padding: 24px;
    border: none;
  }
`;
