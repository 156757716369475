import React from "react";
import styled from "styled-components";

import PrevButton from "./PrevButton";

const constants = require("../../constants");

function CartHeader(props) {
  const { goToPrevStep, step } = props;

  return (
    <HeaderContainer>
      <Topbar>
        <PrevButton goToPrevStep={goToPrevStep} step={step} />
      </Topbar>
      <Bottombar>
        {step === 0 && <Step>購物車</Step>}
        {step === 1 && <Step>結帳頁</Step>}
      </Bottombar>
    </HeaderContainer>
  );
}

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
`;

const Topbar = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Bottombar = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: ${constants.breakpoints.xl}px) {
    align-self: center;
  }
`;

const Step = styled.div`
  font-size: 32px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.64px;
`;

export default CartHeader;
