async function disabledSuperMarket(cart, actions, spec) {
  const { items = [] } = cart;
  if (!spec) {
    return true;
  }

  const results = await Promise.all(
    items.map(async item => {
      const itemSpec = await actions.getProductFromSpec({
        productName: item.name,
        spec: null,
      });
      return itemSpec["allow_ezship"] === true;
    })
  );

  return !results.every(result => result);
}

async function disabledSuperMarketItems(cart, actions, spec) {
  const { items = [] } = cart;
  if (!spec) {
    return [];
  }

  const results = await Promise.all(
    items.map(async item => {
      const itemSpec = await actions.getProductFromSpec({
        productName: item.name,
        spec: null,
      });
      return {
        item,
        isAllowed: itemSpec["allow_ezship"] === true,
      };
    })
  );

  return results.filter(result => !result.isAllowed).map(result => result.item);
}

export { disabledSuperMarket, disabledSuperMarketItems };
