import React from "react";
import styled from "styled-components";

function PrevButton(props) {
  const { goToPrevStep, step } = props;

  return (
    <ButtonContainer
      onClick={() => {
        goToPrevStep();
      }}
    >
      <img src="../../images/arrow_back_ios.svg" width="20px" height="auto" />
      {step === 0 && <Button>繼續購物</Button>}
      {step === 1 && <Button>購物車</Button>}
      {step === 2 && <Button>訂單編號</Button>}
      {step === 3 && <Button>訂單編號</Button>}
    </ButtonContainer>
  );
}

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  align-self: stretch;
  cursor: pointer;
`;

const Button = styled.div`
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.016px;
`;

export default PrevButton;
