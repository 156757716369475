import React, { useState, useCallback, useEffect, useContext } from "react";
import styled from "styled-components";
import LoadingOutlined from "@ant-design/icons/LoadingOutlined";
import CaretDownOutlined from "@ant-design/icons/CaretDownOutlined";
import { ArrowDown } from "../../Widgets/Icon";
import { Spin, Input as AntInput, Select as AntSelect } from "antd";
import { Context } from "../../AppContext";
const constants = require("../../constants");

export default function AddressField({
  onChange,
  zip_code,
  city,
  district,
  address = "",
}) {
  const [loading, setLoading] = useState(false);
  const [counties, setCounties] = useState([]);
  const [towns, setTowns] = useState([]);
  const app = useContext(Context);

  // const getZipCode = useCallback(
  //   async zip_code => {
  //     setLoading(true);
  //     try {
  //       let resp = await app.actions.getZipCode(zip_code);
  //       if (resp) {
  //         onChange({
  //           zip_code,
  //           city: resp.countyName,
  //           district: resp.townName,
  //           address,
  //         });
  //       }
  //     } catch (err) {
  //       console.error(err);
  //     }
  //     setLoading(false);
  //   },
  //   [address]
  // );

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        let resp = await app.actions.getCounties();
        setCounties(resp);
      } catch (err) {
        console.error(err);
      }
      setLoading(false);
    })();
  }, []);

  const getTowns = useCallback(async () => {
    if (city) {
      setLoading(true);
      try {
        let resp = await app.actions.getTowns(city);
        setTowns(resp);
      } catch (err) {
        console.error(err);
      }
      setLoading(false);
    }
  }, [city]);

  useEffect(() => {
    getTowns();
  }, [getTowns]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "16px",
          marginBottom: "8px",
        }}
      >
        <Spin
          spinning={loading}
          indicator={<LoadingOutlined style={{ fontSize: 16 }} />}
          style={{ alignSelf: "center" }}
        />
        {/* <Input
          placeholder="郵遞區號"
          type="short"
          disabled={loading}
          value={zip_code}
          onChange={e => {
            let value = e.target.value;
            onChange({ city, district, address, zip_code: value });
          }}
          onBlur={() => {
            if (/[0-9]{3}/g.test(zip_code)) {
              getZipCode(zip_code);
            }
          }}
        /> */}

        <Select
          placeholder="縣市"
          value={city}
          onChange={value => {
            onChange({
              zip_code: undefined,
              city: value,
              district: undefined,
              address,
            });
          }}
          disabled={loading}
        >
          {counties.map(c => (
            <SelectOption key={c.countyName} value={c.countyName}>
              {c.countyName}
            </SelectOption>
          ))}
        </Select>

        <Select
          placeholder="鄉鎮市區"
          value={district}
          onChange={value => {
            // set zipcode
            let instance = towns.find(t => t.townName === value);

            onChange({
              zip_code: instance?.zipCode || zip_code,
              city,
              district: value,
              address,
            });
          }}
          disabled={loading}
        >
          {towns.map(t => (
            <SelectOption key={t.townName} value={t.townName}>
              {t.townName}
            </SelectOption>
          ))}
        </Select>
      </div>
      <Input
        placeholder="請填寫完整路段/號碼"
        disabled={loading}
        value={address}
        type="long"
        onChange={e =>
          onChange({
            zip_code,
            city,
            district,
            address: e.target.value,
          })
        }
      />
    </div>
  );
}

const Select = styled(AntSelect).attrs({
  suffixIcon: <ArrowDown color={constants.colors.text} size={24} />,
  bordered: false,
  dropdownMatchSelectWidth: false,
})`
  display: flex;
  height: 48px;
  font-size: 16px;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 5px;
  border: 1px solid #b6bac3;
  background-color: ${constants.colors.background};

  .ant-select-arrow {
    top: 40%;
    width: 24px;
  }
`;

const SelectOption = styled(AntSelect.Option)`
  width: 150px;
`;

const Input = styled(AntInput)`
  padding: 8px 16px;
  border: none;
  background-color: ${constants.colors.background};
  margin: 6px 0px;
  flex-basis: 220px;
  flex-shrink: 0;
  ${props => (props.type === "short" ? "flex-basis: 90px; width: 90px;" : "")}
  ${props => (props.type === "long" ? "flex-basis: 320px;" : "")}

  .ant-input::selection {
    border-color: green;
  }
}

`;
