import React, { useState, useContext, useCallback, useEffect } from "react";
import { Input, message, Modal } from "antd";
import styled from "styled-components";
import { Context } from "../../AppContext";
import * as Cart from "../../Contexts/CartContext";
import { Text, Button } from "../../Widgets";
import { errorHandler } from "../../errors";

export default function CouponBox({ config, params, setParams }) {
  const app = useContext(Context);
  const [showDiscountModal, setShowDiscountModal] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [couponInfo, setCouponInfo] = useState(null);
  const [couponError, setCouponError] = useState(null);

  useEffect(() => {
    setCouponCode("");
    setCouponInfo(null);
  }, [config.extraConfig.use_bonus]);

  const getCoupon = useCallback(async code => {
    try {
      let resp = await app.actions.getCoupon(code);
      setCouponInfo(resp);
      return resp;
    } catch (err) {}
  }, []);

  const reset = useCallback(async () => {
    app.actions.setLoading(true);
    try {
      await Cart.Actions.fetchCart();
      setCouponCode("");
      setCouponInfo(null);
      setParams({ ...params, coupon: "" });
      await app.actions.calcOrder({ coupon: "" });
    } catch (err) {
      console.error("Error resetting coupon:", err);
    } finally {
      app.actions.setLoading(false);
    }
  }, [app.actions]);

  const apply = useCallback(
    async code => {
      app.actions.setLoading(true);
      let _coupon = await getCoupon(code);
      try {
        let resp = await app.actions.calcOrder({ coupon: code });
        Cart.Actions.setCart(resp);
        setParams({ ...params, coupon: code });
        setCouponError(null);
        message.success("此折扣代碼可使用");
        setShowDiscountModal(false);
      } catch (err) {
        let re = /Object with code\=\w+ does not exist\./g;
        if (err.coupon && err.coupon.length > 0 && re.test(err.coupon[0])) {
          setCouponError("此折扣代碼不存在");
        }
        if (err.error === "coupon_expired") {
          setCouponError("此折扣代碼過期");
        }
        if (err.error === "coupon_usage_limit") {
          setCouponError("此折扣代碼已兌換完畢");
        }
        if (err.error === "coupon_user_usage_limit") {
          setCouponError("您的折扣代碼已使用達上限");
        }
        if (err.error === "order_coupon_error") {
          setCouponError(
            `訂單金額小於此折扣代碼最低門檻 ${_coupon?.threshold} 元`
          );
        }
        if (err.error === "coupon_user_group_error") {
          setCouponError("無法使用此折扣代碼");
        }
        errorHandler(err);
        reset();
      }
      app.actions.setLoading(false);
    },
    [reset]
  );

  return (
    <StyledCouponBox>
      <Text size="sm">折扣碼</Text>
      {couponInfo ? (
        <StyledText size="sm" onClick={() => setShowDiscountModal(true)}>
          {`-NT$${couponInfo.discount}`}
        </StyledText>
      ) : (
        <StyledText size="sm" onClick={() => setShowDiscountModal(true)}>
          使用折扣碼
        </StyledText>
      )}
      <StyledModal
        title="折扣碼"
        visible={showDiscountModal}
        onCancel={() => setShowDiscountModal(false)}
        footer={null}
      >
        <StyledInput
          className={couponError && "error"}
          placeholder="請輸入折扣碼"
          onChange={e => setCouponCode(e.target.value)}
          value={couponCode}
        />
        {couponError && (
          <Text size="xs" color="red" weight={400} css={"margin-top: 8px;"}>
            {couponError}
          </Text>
        )}
        <Button
          size="lg"
          type="secondary"
          css={"margin-top: 24px; width: 100%;"}
          disabled={!couponCode}
          onClick={() => apply(couponCode)}
        >
          確認
        </Button>
      </StyledModal>
    </StyledCouponBox>
  );
}

const StyledCouponBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledText = styled(Text)`
  cursor: pointer;
  text-decoration: underline;
`;

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 10px;
  }
  .ant-modal-header {
    text-align: start;
    border-radius: 10px 10px 0 0;
    padding: 24px;
    border: none;
    .ant-modal-title {
      color: #000;
      font-size: 24px;
      font-weight: 700;
      line-height: 36px;
      letter-spacing: -0.48px;
    }
  }
  .ant-modal-body {
    padding: 24px;
  }
  .ant-modal-footer {
    display: flex;
    justify-content: end;
    padding: 24px;
    border: none;
  }
  .ant-modal-close-x {
    margin: 24px;
    color: #25272c;
    width: 30px;
    height: 30px;
    line-height: 36px;

    .ant-modal-close-icon {
      width: 100%;
      height: 100%;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  .ant-input {
    border-radius: 5px;
    border: 1px solid #b6bac3;
    padding: 12px 16px;
  }
`;

const StyledInput = styled(Input)`
  border-radius: 5px;
  border: 1px solid #b6bac3;
  padding: 12px 16px;

  &.error {
    border-color: red;
    color: red;
  }
`;
